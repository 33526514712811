<template>
  <div>
    <v-card>
      <button
        type="button"
        class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--default primary"
        title="Vložit článek"
        @click="open_form_clanky=true"
      >
        <span
          class="v-btn__content"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate theme--light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              role="img"
              aria-hidden="true"
              class="v-icon__svg"
            >
              <path
                d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              >
              </path>
            </svg>
          </span>
        </span>
      </button>
    </v-card>
    <v-card
      :loading="loading"
      :style="`height:${desktop_h - 160}px;padding:10px;`"
    >
      <div
        :style="`height:${desktop_h - 230}px;overflow-y: scroll;`"
      >
        <v-card
          v-for="(clanok, index) in clanky"
          :key="clanok._id"
          elevation="2"
          outlined
          style="margin-bottom:10px;"
        >
          <!--  TODO: dorobit aby mohol mat clanok titulny obrazok -->
          <!-- <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          ></v-img> -->

          <v-card-title>
            <div
              style="width:100%;"
              @mouseover="small_show_btn=index"
              @mouseleave="small_show_btn=-1"
            >
              <div class="text-xl font-weight-semibold primary--text mb-2">
                <div style="float:left;line-height:36px;">
                  {{ clanok.nazev }}
                </div>
                <div
                  v-show="(small_show_btn==index && $store.getters.getProfile._id === clanok.autor[0]._id) || (small_show_btn==index && $store.getters.getProfile.is_admin)"
                  style="float:right;"
                >
                  <v-fab-transition>
                    <v-btn
                      color="primary"
                      fab
                      x-small
                      style="margin-right:5px"
                      @click="onEditFormClanky(clanok._id)"
                    >
                      <v-icon>{{ mdiPencil }}</v-icon>
                    </v-btn>
                  </v-fab-transition>
                  <v-fab-transition>
                    <v-btn
                      color="error"
                      fab
                      x-small
                      @click="onDeleteFormClanky(clanok._id)"
                    >
                      <v-icon>{{ mdiTrashCan }}</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </div>
              </div>
            </div>
          </v-card-title>

          <v-card-subtitle>
            {{ clanok.datum | moment("DD.MM.YYYY HH:mm") }}
            <span style="color:#9255FD">{{ clanok.autor[0].jmeno }} {{ clanok.autor[0].prijmeni }}</span>
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
              color="orange lighten-2"
              text
              @click="showDetail(clanok._id)"
            >
              Zobrazit podrobnosti
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              icon
              @click="showDetail(clanok._id)"
            >
              <v-icon>{{ show.includes(clanok._id) ? mdiChevronUp : mdiChevronDown }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show.includes(clanok._id)">
              <v-divider></v-divider>

              <v-card-text>
                <p v-html="clanok.text"></p>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
      <!-- STRANKOVANIE -->
      <div
        class="text-center"
        style="width:100%;position:absolute;bottom:0px;padding:10px;"
      >
        <v-pagination
          v-model="fetch_settings.paging.current_page"
          :length="fetch_settings.paging.total_pages"
          :total-visible="10"
          circle
          @input="handlePaging"
        ></v-pagination>
      </div>
    </v-card>

    <form-clanky
      :show="open_form_clanky"
      :title="`${item_id ? 'Upravit článek' : 'Vložit nový článek'}`"
      :type="2"
      :item_id="item_id"
      @close="onCloseFormClanky(...arguments)"
    >
    </form-clanky>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>

    <!-- ZMAZAT DIALOG -->
    <v-dialog
      v-model="open_dialog_zmazat"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h7">
          Upozornění
        </v-card-title>

        <v-card-text>
          Skutečne chcete odstranit článek?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="open_dialog_zmazat = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="deleteFormClanky()"
          >
            Odstranit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiPencil,
  mdiTrashCan,
} from '@mdi/js'
import axios from 'axios'
import latinize from 'latinize'
import SnackBar from '@/components/snackbar/index.vue'
import FormClanky from '@/components/form-clanky/index.vue'
import response from '@/mixins/response'

export default {
  components: {
    'snack-bar': SnackBar,
    'form-clanky': FormClanky,
  },
  mixins: [response],
  data() {
    return {
      item_id: '',
      loading: false,
      mdiChevronUp,
      mdiChevronDown,
      mdiPencil,
      mdiTrashCan,
      desktop_w: 1024,
      desktop_h: 768,
      small_show_btn: -1,
      show: [],
      clanky: [],
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      open_form_clanky: false,
      open_dialog_zmazat: false,
      fetch_settings: {
        filter: {
          typ_zpravy: 2,
          active: true,
          smazane: false,
        },
        paging: {
          limit: 4,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          datum: -1,
        },
      },
    }
  },
  watch: {
    '$store.state.global.filter_search': {
      handler(_newVal) {
        const filter = this.buildFilter(latinize(_newVal).toLowerCase())
        this.fetchClanky(filter)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    this.fetchClanky()
  },
  methods: {
    windowResize() {
      this.desktop_w = window.innerWidth
      this.desktop_h = window.innerHeight
    },
    handlePaging(p_page) {
      // vypocitaj hodnotu skip a potom zavolaj nove nacitanie dat z DB
      const { limit } = this.fetch_settings.paging
      this.fetch_settings.paging.skip = (limit * p_page) - limit
      this.fetchClanky()
    },
    buildFilter(_search) {
      const filter = { // default
        typ_zpravy: 2,
        active: true,
        smazane: false,
      }
      if (_search && _search.length > '') {
        const search = { $regex: `${_search}`, $options: 'i' }
        filter.$or = [
          { latin_nazev: search },
          { latin_text: search },
        ]
      }

      return filter
    },
    fetchClanky(p_filter) {
      this.loading = true
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/clanky/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.clanky = resp.data.db_data
          console.log(this.clanky)
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    showDetail(p_id) {
      if (this.show.includes(p_id)) {
        const index = this.show.indexOf(p_id)
        if (index > -1) {
          this.show.splice(index, 1)
        }
      } else {
        this.show.push(p_id)
      }
    },
    onEditFormClanky(p_id) {
      this.item_id = p_id
      this.open_form_clanky = true
    },
    onDeleteFormClanky(p_id) {
      this.item_id = p_id
      this.open_dialog_zmazat = true
    },
    deleteFormClanky() {
      this.open_dialog_zmazat = false
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/clanky/${this.item_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'delete',
      }
      axios(config)
        .then(resp => {
          console.log('onDeleteFormClanky => ', resp)
          this.fetchClanky()
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    onCloseFormClanky(p_param) {
      this.open_form_clanky = false
      this.item_id = undefined
      if (p_param.refresh !== false) {
        this.fetchClanky()
      }
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>
